import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const newsQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`

const News = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <section id="slider" className="hero p-0 odd featured mt-5 mt-lg-0">
        <div className="swiper-container no-slider animation slider-h-50 slider-h-auto">
          <div className="swiper-wrapper">
            <BackgroundImage
              Tag="section"
              className="swiper-slide slide-center post-hero"
              fluid={post.frontmatter.image.childImageSharp.fluid}
            >
              <div className="slide-content row text-center">
                <div className="col-12 mx-auto inner">
                  <nav
                    data-aos="zoom-out-up"
                    data-aos-delay="800"
                    aria-label="breadcrumb"
                  >
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/news">News</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {post.frontmatter.title}
                      </li>
                    </ol>
                  </nav>
                  <h1 className="mb-0 title effect-static-text">
                    {post.frontmatter.title}
                  </h1>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default News
